import React, { useCallback } from 'react';

import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { FetchInvoicesCacheKeys, InvoiceID } from '../../../invoicesTypes';

import { useVoidInvoice } from '../../../hooks/useVoidInvoice';

import { ConfirmModalButton } from '../../../../../helpers/buttons/ConfirmModalButton';

import {
  invoicesKeys,
  operationsKeys,
  words
} from '../../../../../locales/keys';
import CreateOperationModalButton from '../../../../operations/components/modalButtons/CreateOperationModalButton/CreateOperationModalButton';
import { TeamsPermissions } from '../../../../teams/teamsConstants';

interface VoidInvoiceModalButtonProps {
  cacheKeys?: FetchInvoicesCacheKeys;
  className?: ClassName;
  disabled?: IsDisabled;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  invoiceId: InvoiceID;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
}
function VoidInvoiceModalButton({
  cacheKeys,
  className,
  disabled,
  icon,
  iconClassName,
  invoiceId,
  i18nText,
  tooltipI18nText
}: VoidInvoiceModalButtonProps) {
  const { voidInvoice, voidInvoiceErrorMessage, voidInvoiceLoading } =
    useVoidInvoice({ cacheKeys });

  const handleVoidInvoice = useCallback(
    () => voidInvoice({ invoiceId }),
    [voidInvoice, invoiceId]
  );

  return (
    <ConfirmModalButton
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      disabled={voidInvoiceLoading || disabled}
      errorMessage={voidInvoiceErrorMessage}
      i18nButtonText={i18nText}
      i18nSubmitText={words.yes}
      i18nCancelText={words.no}
      i18nText={invoicesKeys.areYouSureYouWantToCancelThisInvoice}
      i18nTitle={words.void}
      tooltipI18nText={tooltipI18nText}
      icon={icon}
      iconClassName={iconClassName}
      isLoading={voidInvoiceLoading}
      modalIcon={IconsEnum.EXCLAMATION}
      modalIconClassName="h-6 w-6 text-red-600"
      onSubmit={handleVoidInvoice}
    />
  );
}

export default VoidInvoiceModalButton;
